import { onCleanup, onMount } from "solid-js";

export function useOutsideClick(
  element: () => HTMLElement | undefined,
  callback: () => void,
  excludeElement?: () => HTMLElement | undefined
) {
  const handleClick = async (event: MouseEvent) => {
    const targetElement = element();
    const excludedElement = excludeElement?.();

    if (!targetElement || targetElement.contains(event.target as Node)) {
      return;
    }

    if (excludedElement && excludedElement.contains(event.target as Node)) {
      return;
    }

    // await new Promise((resolve) => setTimeout(resolve, 100));

    callback();
  };

  onMount(() => {
    document.addEventListener("mousedown", handleClick);

    onCleanup(() => {
      document.removeEventListener("mousedown", handleClick);
    });
  });
}
